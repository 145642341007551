import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";

import PrivateRoute from "@components/Authentication/PrivateRoute";
import LoadingError from "@components/Loaders/LoadingError";

import api from "../../../utils/api/api";
import moment from "moment/moment";
import {Link} from "gatsby";
import {navigate} from "gatsby-link";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MuiAlert from "@material-ui/lab/Alert";

const Header = styled.div`
  font-size: 2rem;
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


const BackArrow = styled(ArrowBackIcon)`
  cursor: pointer;
`;

interface OperativeEditProps {
  id: string;
}

const OperativeEdit = ({ id }: OperativeEditProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [status, setStatus] = useState<string>("starting");
  const [messages, setMessages] = useState<Array<Object>>([]);
  const ref = useRef(null);

  const working_status = ["working", "starting"];
  const bring_messages = async () => {
    setError("");
    try {
      if (!working_status.includes(status)) {
        return;
      }
      const response = await api.get(`/v2/files-generated/${id}/`);
      setMessages(response.data.messages);
      setStatus(response.data.status);
      setLink(response.data.link_download);
    } catch (e) {
      setError(e.message);
    }
  };
  useEffect(() => {
    ref.current = setInterval(bring_messages, 5 * 1000);
    return () => {
      if (working_status.includes(status)) {
        clearInterval(ref.current);
      }
    };
  }, []);
  const translate_status = {
    working: "Generando",
    error: "Error al generar",
    finished: "Archivo listo para bajar",
  };
  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && (
        <>
          <Header>
         <BackArrow
          onClick={() => {
                navigate('/dashboard/totalpack');
              }}
        />
              <h2>Descarga masiva de Totalpack</h2>
            <div></div>
          </Header>
          {status == "starting" && (
            <>
              <h3> Cargando información </h3>
            </>
          )}
          {!["starting", "finished"].includes(status) && (
            <>
              <MuiAlert variant="standard" severity={{working: "warning",
error: "error",
finished: "success"}[status]} icon={false}>
              Archivo en: {translate_status[status]}
              </MuiAlert>
              <br/>
              <ul>
              {messages
                .filter((x) => x.to_all_users)
                .reverse()
                .map((i, key) => (
                  <li>
                    <p>
                      {moment(i.created_at).format("HH:mm")}{" - "}
                      {i.message}
                    </p>
                  </li>
                ))}
              </ul>

            </>
          )}
          {status == "finished" && (
            <>
              <h3>Archivo listo para descargar</h3>
                <Link to={link} >aquí</Link>.
            </>
          )}
        </>
      )}
    </PrivateRoute>
  );
};

export default OperativeEdit;
